/* ./styles/globals.css */
@import './fonts.css';

@tailwind base;
@tailwind components;

html {
  @apply antialiased;
  @apply font-sans;
}

body {
  min-width: 321px;
  @apply antialiased;
  @apply font-sans;
  @apply text-secondary;
  @apply bg-neutral-dark;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@tailwind utilities;
